
import { defineComponent, ref, watch } from 'vue'
import TmTabs from '@/components/shared/TmTabs.vue'
import type { Tab } from '@/components/shared/TmTabs.vue'
import type { PropType } from 'vue'

export default defineComponent({
  components: {
    TmTabs,
  },
  props: {
    title: {
      type: String,
    },
    text: {
      type: String,
    },
    currentTab: {
      type: String,
    },
    tabs: {
      type: Array as PropType<Tab[]>,
      default: () => [],
    },
  },
  emits: ['update:tab'],
  setup(props, context) {
    const tab = ref(props.tabs.length ? (props.currentTab || props.tabs[0].name) : '')

    watch(() => props.currentTab, (newValue: any) => {
      tab.value = newValue
    })
    watch(() => tab.value, (newValue: any) => {
      context.emit('update:tab', newValue)
    })

    return {
      tab,
    }
  },
})
